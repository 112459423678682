import { useAbility } from '@casl/react'
import { selectUser } from 'src/container/auth/auth.selector'
import { useAppSelector } from 'src/store'
import { AbilityContext } from 'src/utils/permissionsCASL'
import { getAccountById } from 'src/container/my_account/account.selector'
import { useEffect, useState } from 'react'
import { useActions } from 'src/actions'

interface IProps {
  title?: string
  text?: string
}

const AlertRoutera: React.FC<IProps> = ({ title, text }) => {
  const { getAccount, hideAllocationLimitsBanner } = useActions()
  const ability = useAbility(AbilityContext)
  const currentUser = useAppSelector(selectUser)
  const account = useAppSelector(getAccountById)
  const [allocationLimit, setAllocationLimit] = useState(false)
  const [message, setMessage] = useState('')
  const [currentLimit, setCurrentLimit] = useState(0)
  const [nextLimit, setNextLimit] = useState()
  const [ninety, setNinety] = useState(false)

  useEffect(() => {
    if (currentUser && currentUser.account_id) {
      getAccount(currentUser.account_id)
    }
  }, [currentUser])

  useEffect(() => {
    const quantity = (+account.additional_allocation_limit - 1) * 1000
    const previous = +account.allocation_limit_per_plan + quantity

    setCurrentLimit(previous)
    setNextLimit(account.total_allocation_limit)
    if (account.show_banner_ninety_percent_assignments) {
      setNinety(true)
      setAllocationLimit(true)
      setMessage(
        `You've used 90% of your assignment limit. If you exceed ${currentLimit} assignments, you'll be upgraded to the next tier and billed for the additional cost immediately. You can review your tier on your`,
      )
    } else if (account.show_banner_hundred_percent_assignments) {
      setAllocationLimit(true)
      setMessage(
        `You've exceeded the ${currentLimit} assignments included in your tier. You've automatically been upgraded to the next tier, which includes ${nextLimit} assignments. You can review this on your`,
      )
    }
  }, [account])

  const hideBanner = async (percentage: string) => {
    const payload = {
      percentage,
    }
    await hideAllocationLimitsBanner(payload)
    setAllocationLimit(false)
  }

  return (
    <div>
      {ability.can('read', 'setting.billing') && allocationLimit && (
        <>
          <div
            id='banner'
            tabIndex={-1}
            className='flex h-auto z-50 gap-8 justify-between items-center py-3 px-4 w-full bg-yellow-100 border border-b border-yellow-200 sm:items-center dark:border-gray-700 lg:py-4 dark:bg-gray-800'
          >
            <div>
              <span className='text-sm text-wrap font-light text-gray-700 dark:text-gray-400'>
                {message} <a href='/settings/billing'>billing page</a>
              </span>
            </div>
            <div className='flex items-center'>
              <button
                onClick={() => hideBanner(ninety ? 'ninety' : 'hundred')}
                data-dismiss-target='#sticky-banner'
                type='button'
                className='flex-shrink-0 inline-flex justify-center w-7 h-7 items-center text-gray-400 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 dark:hover:bg-gray-600 dark:hover:text-white'
              >
                <svg
                  className='w-3 h-3'
                  aria-hidden='true'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 14 14'
                >
                  <path
                    stroke='currentColor'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth='2'
                    d='m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6'
                  />
                </svg>
                <span className='sr-only'>Close banner</span>
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default AlertRoutera
