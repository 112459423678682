import Header from 'src/components/Header'
import { selectToken } from 'src/container/auth/auth.selector'
import { useAppSelector } from 'src/store'
import MenuBar from 'src/components/Menu/menu'
import { useLocation } from 'react-router-dom'
import AlertRoutera from './components/atoms/Alert/AlertRoutera'

const Layout: React.FC<{
  children: React.ReactNode
}> = ({ children }) => {
  const token = useAppSelector(selectToken)
  const location = useLocation()

  return (
    <div className='h-[100vh]'>
      {token && <Header />}
      {token && <AlertRoutera></AlertRoutera>}
      <div className='flex min-h-[calc(100%-64px)]'>
        {token && location.pathname.includes('/settings') && <MenuBar />}
        <div
          className={`${
            location.pathname.includes('/settings')
              ? 'w-[calc(100%-70px)] overflow-auto'
              : 'w-[100%]'
          } bg-routera-gray h-full`}
        >
          {children}
        </div>
      </div>
    </div>
  )
}

export default Layout
