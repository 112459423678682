import React, { useEffect } from 'react'

interface IProps {
  plan: any
  type?: string
  planType?: string
  currentPlanId?: string
  className?: string
  handleUpgradePlan?: (
    stripe_plan_id: string,
    seats: number,
    price: number,
  ) => void
  loadingId?: string
  currentPlanPrice?: number
  disabled?: boolean
  routers?: number
  assignments?: number
}

const encodeUserEmail = (email: string) => {
  return encodeURIComponent(email)
}

const BillingCard: React.FC<IProps> = ({
  plan,
  type = '',
  planType = '',
  currentPlanId = '',
  handleUpgradePlan,
  className = '',
  loadingId = '',
  currentPlanPrice = 0,
  disabled = false,
}) => {
  const isTrial =
    plan?.status?.toLowerCase().includes('trial') ||
    plan?.title?.toLowerCase().includes('trial')

  return (
    <div
      className={`rounded-lg items-center justify-center flex flex-col border shadow-sm hover:scale-105 transition-transform w-[280px] ${className}`}
    >
      {planType === 'yearly' && (
        <div
          className={`h-[25px] w-[100%] mt-4 items-center flex justify-center ${
            plan?.price !== 0 && 'bg-routera-700'
          }`}
        >
          {plan?.price !== 0 && (
            <span className='text-xs text-white'>
              🎉 {`Save $${plan?.saveAmount} yearly`}
            </span>
          )}
        </div>
      )}
      <div className='px-4 py-4 items-center justify-center flex flex-col'>
        <div className='flex items-center justify-center gap-x-4 pt-2'>
          <span id='tier' className='font-medium text-[#92929D] text-xl'>
            {plan?.status?.toLowerCase().includes('trial')
              ? 'Free Trial'
              : plan?.title}
          </span>
        </div>
        {plan && 'price' in plan && plan?.price !== undefined && (
          <p className='mt-2 mb-2 flex items-baseline gap-x-1'>
            <span className='text-[48px] font-extrabold tracking-tight text-gray-900'>
              {isTrial ? `${plan?.daysRemaining || 0} Days` : `$${plan?.price}`}
              {planType === 'yearly' && plan?.price !== 0}
            </span>
            {!isTrial && plan && type !== 'current' && (
              <span className='text-[24px] font-semibold text-[#92929D]'>
                {plan?.price !== 0 &&
                  (type === 'current' && 'planType' in plan
                    ? '/' + (plan.planType.interval === 'year' ? 'yr' : 'mo')
                    : '/mo')}
              </span>
            )}
          </p>
        )}
        {(type === 'current' && isTrial) ||
        planType === 'monthly' ||
        planType === 'yearly' ? (
          <p className={`flex mt-1 mb-0 text-gray-900`}>
            <span className='font-medium text-gray-800 text-base'>
              {type === 'current' && isTrial
                ? 'Remaining'
                : planType === 'monthly' &&
                  `Billed ${planType[0].toUpperCase() + planType?.slice(1)}`}
            </span>
          </p>
        ) : type === 'current' && plan && 'planType' in plan ? (
          <span className='font-medium text-gray-800 text-base capitalize'>
            Every {plan.planType.interval_count}{' '}
            {plan.planType.interval +
              (plan.planType.interval_count > 1 ? 's' : '')}
          </span>
        ) : null}
        <ul role='list' className='mt-3 p-0 space-y-4 text-sm text-[#92929D]'>
          <li className='flex justify-center gap-x-3 text-base text-gray-500 font-light'>
            Includes {plan?.seats || '-'} seats
          </li>

          <li className='flex justify-center gap-x-3 text-base text-gray-500 font-light'>
            {plan?.maxAssignments
              ? `Up to ${plan?.maxAssignments} assignments`
              : 'Unlimited assignments'}
          </li>

          <li className='flex justify-center gap-x-3 text-base text-gray-500 font-light'>
            User availability & OOO
          </li>

          <li className='flex justify-center gap-x-3 text-base text-gray-500 font-light'>
            {plan?.maxRouters
              ? `Max ${plan?.maxRouters} Routers`
              : 'Unlimited Routers'}
          </li>

          {plan && plan.capacities === 'true' && (
            <li className='flex justify-center gap-x-3 text-base text-gray-500 font-light'>
              Capacities
            </li>
          )}

          {plan && plan.sla === 'true' && (
            <li className='flex justify-center gap-x-3 text-base text-gray-500 font-light'>
              SLA's
            </li>
          )}

          {plan && plan.account_matching === 'true' && (
            <li className='flex justify-center gap-x-3 text-base text-gray-500 font-light'>
              Account Matching
            </li>
          )}
        </ul>
        {type !== 'current' && (
          <button
            onClick={() =>
              handleUpgradePlan &&
              handleUpgradePlan(
                plan?.stripe_plan_id,
                plan?.seats,
                planType === 'yearly' ? plan?.yearlyPrice : plan?.price,
              )
            }
            aria-describedby='tier-startup'
            className={`px-[20px] py-[10px] text-white text-[14px] rounded-lg bg-routera-primary self-start w-[188px] ${
              currentPlanId === plan.stripe_plan_id &&
              'bg-gray-400 pointer-events-none'
            } ${loadingId === plan.stripe_plan_id && 'pointer-events-none'} ${
              disabled && 'cursor-not-allowed'
            } ${plan?.title.includes('Free') ? 'mt-6' : 'mt-2'} `}
            disabled={disabled}
          >
            {loadingId === plan.stripe_plan_id ? (
              <div role='status' className='flex items-center justify-center'>
                <svg
                  aria-hidden='true'
                  className='w-6 h-6 text-white animate-spin dark:text-gray-600 fill-blue-600'
                  viewBox='0 0 100 101'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
                    fill='currentColor'
                  />
                  <path
                    d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
                    fill='currentFill'
                  />
                </svg>
              </div>
            ) : currentPlanId === plan.stripe_plan_id ? (
              'Current'
            ) : currentPlanPrice > plan.price ? (
              'Downgrade'
            ) : (
              'Upgrade'
            )}
          </button>
        )}
      </div>
    </div>
  )
}

export default BillingCard
