import { ChangeEvent, useEffect, useState } from 'react'
import { Dialog } from '@headlessui/react'

import { useActions } from 'src/actions'
import {
  getAccountById,
  getallusers,
} from 'src/container/my_account/account.selector'
import { subscriptonService } from 'src/services/subscription.service'
import { useAppSelector } from 'src/store'
import toastBuilder from 'src/utils/toastBuilder'
import BillingCard from '../atoms/BillingCard/BillingCard.atom'
import { ModalAtom } from '../atoms/Modal/Modal.atom'
import Input from '../atoms/Input/Input.atom'
import { selectUser } from 'src/container/auth/auth.selector'
import { useListPlans } from 'src/container/Plan/plan.selector'
import { getcurrentuser, setcurrentuser } from 'src/utils/localstorage'
import { AbilityContext } from 'src/utils/permissionsCASL'
import { useAbility } from '@casl/react'
import { config } from 'src/config/constants'
import { uselistofAllroutes } from './../../container/leadRouter/leadRoutes.selector'

const { FREE_PLAN_MONTHLY, FREE_PLAN_PRICE_IDS } = config

const plansType = [
  { id: 'yearly', title: 'Yearly Plans' },
  { id: 'monthly', title: 'Monthly Plans' },
]

const getPlanPrice = (
  planId: string,
  plans: Record<string, any>,
  planType: string,
) => {
  let filteredPlan = null
  filteredPlan = plans[planType === 'month' ? 'monthly' : 'yearly'].find(
    (plan: Record<string, any>) => plan.stripe_plan_id === planId,
  )

  return filteredPlan?.price
}

const Billing = () => {
  const { getallcustomer, getUserDetailsAction, getAccount, getallRoutes } =
    useActions()
  const allUsers = useAppSelector(getallusers)
  const currentUser = useAppSelector(selectUser)
  const allPlans = useAppSelector(useListPlans)
  const account = useAppSelector(getAccountById)
  const ListofAllroutes = useAppSelector(uselistofAllroutes)
  const [planType, setPlanType] = useState('monthly')
  const [showModal, setShowModal] = useState(false)
  const [hasPaymentMethod, setHasPaymentMethod] = useState(false)
  const [seats, setSeats] = useState(0)
  const [plans, setPlans] = useState<Record<string, any>>({}) // TODO add types
  const [currentPlan, setCurrentPlan] = useState<Record<string, any>>() // TODO add types
  const [upgradeLoadingId, setUpgradeLoadingId] = useState('')
  const [upgradePlanData, setUpgradePlanData] = useState<{
    stripe_plan_id: string
    seats: number
    price: number
  } | null>()
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [freePlan, setFreePlan] = useState(false)
  const [planHasLimitAllocation, setPlanHasLimitAllocation] = useState(false)
  const [planHasLimitRouters, setPlanLimitRouters] = useState(false)
  const [showAddSeatButton, setShowAddSeatButton] = useState(true)

  const ability = useAbility(AbilityContext)

  useEffect(() => {
    if (currentUser && currentUser.account_id) {
      getAccount(currentUser.account_id)
    }
  }, [])

  useEffect(() => {
    if (currentPlan && currentPlan.maxAssignments !== 'unlimited') {
      setPlanHasLimitAllocation(true)
    }

    if (
      currentPlan &&
      FREE_PLAN_PRICE_IDS &&
      FREE_PLAN_PRICE_IDS.includes(currentPlan.planId)
    ) {
      setShowAddSeatButton(false)
    } else {
      setShowAddSeatButton(true)
    }
  }, [currentPlan])

  useEffect(() => {
    getUserDetailsAction()
    getallcustomer()
    getStoredPaymentMethods()
    getallRoutes()
  }, [])

  useEffect(() => {
    getCurrentPlanHelper().then((res) => {
      let totalLimit = currentUser.plan_assignments

      if (account.additional_allocation_limit) {
        totalLimit =
          currentUser.plan_assignments +
          1000 * account.additional_allocation_limit
      }
      setCurrentPlan({
        id: 'currentPlan',
        title: res.data.title ? res.data.title : '',
        daysRemaining: res.data.daysRemaining,
        planType: res.data.planType,
        seats: currentUser.max_seats,
        planId: res.data.planId,
        status: res.data.planStatus,
        price: res.data.price,
        maxAssignments: totalLimit,
        maxRouters: currentUser.plan_routers,
        account_matching: res.data.account_matching,
        sla: res.data.sla,
        capacities: res.data.capacities,
        conversion: res.data.conversion,
      })
    })
  }, [currentUser, allPlans])

  useEffect(() => {
    if (allPlans) {
      const plansAux = { ...allPlans }
      const plansMonthly = [...plansAux.monthly]
      const plansYearly = [...plansAux.yearly]

      plansMonthly.sort((a, b) => {
        return a.price - b.price
      })
      plansYearly.sort((a, b) => {
        return a.price - b.price
      })

      plansAux.monthly = plansMonthly
      plansAux.yearly = plansYearly
      setPlans(plansAux)
      permissions()
    }
  }, [allPlans])

  const permissions = () => {
    const plansAux = JSON.parse(JSON.stringify(allPlans)) // Crea una copia profunda
    const plansMonthly = [...plansAux.monthly]
    const plansYearly = [...plansAux.yearly]

    let sla, account_matching, capacities, conversion
    plansMonthly.forEach((plan: any, index: number) => {
      if (plan.permissions) {
        sla = plan.permissions.find(
          (permission: any) => permission.subject === 'router.sla',
        )

        account_matching = plan.permissions.find(
          (permission: any) => permission.subject === 'router.account_matching',
        )
        capacities = plan.permissions.find(
          (permission: any) => permission.subject === 'setting.capacities',
        )

        conversion = plan.permissions.find(
          (permission: any) => permission.subject === 'conversion',
        )

        plansMonthly[index].sla = sla ? 'true' : 'false'
        plansMonthly[index].account_matching = account_matching
          ? 'true'
          : 'false'
        plansMonthly[index].capacities = capacities ? 'true' : 'false'
        plansMonthly[index].conversion = conversion ? 'true' : 'false'
      }
    })

    let slaY, account_matchingY, capacitiesY, conversionY
    plansYearly.forEach((plan: any, index: number) => {
      if (plan.permissions) {
        slaY = plan.permissions.find(
          (permission: any) => permission.subject === 'router.sla',
        )

        account_matchingY = plan.permissions.find(
          (permission: any) => permission.subject === 'router.account_matching',
        )
        capacitiesY = plan.permissions.find(
          (permission: any) => permission.subject === 'setting.capacities',
        )

        conversionY = plan.permissions.find(
          (permission: any) => permission.subject === 'conversion',
        )

        plansYearly[index].sla = slaY ? 'true' : 'false'
        plansYearly[index].account_matching = account_matchingY
          ? 'true'
          : 'false'
        plansYearly[index].capacities = capacitiesY ? 'true' : 'false'
        plansYearly[index].conversion = conversionY ? 'true' : 'false'
      }
    })

    plansAux.monthly = plansMonthly
    plansAux.yearly = plansYearly
    setPlans(plansAux)
  }

  const getCurrentPlanHelper = async () => {
    return await subscriptonService.getCurrentPlan()
  }

  const getStoredPaymentMethods = async () => {
    const paymentMethods = await subscriptonService.getStoredCards()
    if (paymentMethods && paymentMethods?.data?.length) {
      setHasPaymentMethod(true)
    }
  }

  const onViewBilling = async () => {
    const rsp = await subscriptonService.viewBillingPortal()
    if (rsp && rsp.data) {
      const payload = rsp.data
      window.location.href = payload.url
      return
    } else {
      toastBuilder(
        'error',
        'Unable to view billing portal now. Please try again later',
      )
      return
    }
  }

  const resetSeatsState = () => {
    setSeats(0)
  }

  const handleSeatsUpdate = async () => {
    const seatsToAdd = seats
    if (seatsToAdd > 0) {
      const updatedAccountRes = await subscriptonService.addSeats({
        max_seats: seatsToAdd,
      })
      if (updatedAccountRes) {
        const currUserData = getcurrentuser()
        currUserData.max_seats = updatedAccountRes.data.max_seats
        setcurrentuser(currUserData)
        setShowModal(false)
        toastBuilder('success', 'User seats added successfully')
        location.reload()
      }
    }
  }

  const handleUpgradePlan = async (
    stripe_plan_id: string,
    seats: number,
    price: number,
  ) => {
    if (hasPaymentMethod) {
      setUpgradePlanData({
        stripe_plan_id,
        seats,
        price,
      })
      setShowConfirmation(true)
    } else {
      onAddCard()
    }
  }

  const onUpgradeConfirm = async () => {
    setShowConfirmation(false)
    setUpgradeLoadingId(upgradePlanData?.stripe_plan_id || '')
    const upgradePlanResponse = await subscriptonService.upgradePlan({
      planId: upgradePlanData?.stripe_plan_id,
      quantity: upgradePlanData?.seats,
    })
    if (upgradePlanResponse && upgradePlanResponse.data) {
      const currUserData = getcurrentuser()
      currUserData.max_seats = upgradePlanResponse.data.max_seats
      setcurrentuser(currUserData)
      toastBuilder('success', 'Plan upgrade successful')
      setTimeout(() => {
        setUpgradeLoadingId('')
        location.reload()
      }, 2000)
      return
    }
    setUpgradeLoadingId('')
    toastBuilder('error', 'Plan upgrade failed')
    setUpgradePlanData(null)
  }

  const onAddCard = async () => {
    const cardSetupURLResponse = await subscriptonService.addCardSession({})
    if (cardSetupURLResponse?.data) {
      window.location.href = cardSetupURLResponse.data
    } else {
      toastBuilder('error', 'Unable to redirect to card setup portal')
    }
  }

  return (
    <div className='px-5 py-7'>
      <div>
        <div className='flex justify-between items-center flex-wrap'>
          <span className='text-black font-medium mb-3 text-lg'>
            Current Plan Information
          </span>
          <button
            className={`px-[20px] py-[10px] mt-2 text-white text-[14px] rounded-lg bg-routera-primary self-start ${
              !ability.can('update', 'setting.billing') && 'cursor-not-allowed'
            }`}
            onClick={onViewBilling}
            disabled={!ability.can('update', 'setting.billing')}
          >
            View Billing Portal
          </button>
        </div>
        <div className='mt-2 flex gap-x-7 flex-wrap'>
          <BillingCard plan={currentPlan} type='current' />
          <div className='flex flex-col tablet:mt-4 w-[280px]'>
            <div
              className={`flex rounded-lg flex-col border shadow-sm px-4 py-4 w-[100%] ${
                planHasLimitAllocation || planHasLimitRouters
                  ? 'h-[230px]'
                  : 'h-[100px]'
              } `}
            >
              <p className='flex m-0'>
                <span className='text-sm font-medium text-gray-800'>
                  Users / Seats
                </span>
              </p>
              <p className={`flex items-baseline gap-x-1 mb-[1rem]`}>
                <span className='font-bold text-2xl tracking-tight text-gray-800'>
                  {allUsers?.length}
                </span>
                <span className='font-semibold text-xl text-gray-500'>
                  /{currentUser.max_seats}
                </span>
              </p>

              {planHasLimitAllocation && (
                <>
                  <p className='flex m-0'>
                    <span className='text-sm font-medium text-gray-800'>
                      Assignments / Limit
                    </span>
                  </p>
                  <p className='flex items-baseline gap-x-1 mb-[1rem]'>
                    <span className='font-bold text-2xl tracking-tight text-gray-800'>
                      {account.total_assignments}
                    </span>
                    <span className='font-semibold text-xl text-gray-500'>
                      / {currentPlan && currentPlan.maxAssignments}
                    </span>
                  </p>
                  <p className='flex m-0'>
                    <span className='text-sm font-medium text-gray-800'>
                      Routers / Limit
                    </span>
                  </p>
                  <p className='flex items-baseline gap-x-1 mb-[1rem]'>
                    <span className='font-bold text-2xl tracking-tight text-gray-800'>
                      {ListofAllroutes.length}
                    </span>
                    <span className='font-semibold text-xl text-gray-500'>
                      / {currentUser.plan_routers}
                    </span>
                  </p>
                </>
              )}
            </div>

            {showAddSeatButton && (
              <div className='mt-4'>
                <button
                  className={`px-[20px] py-[10px] mt-2 text-white text-[14px] rounded-lg self-start w-[109px] ${
                    !currentPlan ||
                    (currentPlan &&
                      currentPlan?.title?.toLowerCase().includes('trial'))
                      ? 'bg-gray-200 cursor-not-allowed'
                      : 'bg-routera-primary'
                  } ${
                    !ability.can('update', 'setting.billing') &&
                    'cursor-not-allowed'
                  }`}
                  onClick={() => setShowModal(true)}
                  disabled={
                    !currentPlan ||
                    (currentPlan &&
                      currentPlan?.title?.toLowerCase().includes('trial')) ||
                    !ability.can('update', 'setting.billing')
                  }
                >
                  <span className='font-medium text-white'>Add Seats</span>
                </button>
              </div>
            )}
            {!hasPaymentMethod && (
              <div className='mt-2'>
                <button
                  className={`px-[20px] py-[10px] mt-2 text-white text-[14px] rounded-lg bg-routera-primary self-start w-[109px] ${
                    !ability.can('update', 'setting.billing') &&
                    'cursor-not-allowed'
                  }`}
                  onClick={onAddCard}
                  disabled={!ability.can('update', 'setting.billing')}
                >
                  <span className='font-medium'>Add Card</span>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className='mt-10'>
        <span className='font-medium text-gray-700 text-lg'>
          Upgrade Options
        </span>
        <div className='mt-4 flex tablet:flex-wrap'>
          {plansType.map((plan) => (
            <div key={plan.id} className='flex flex-col tablet:mb-4 mr-5'>
              <div className='flex items-center px-3 py-1 bg-white shadow-sm rounded'>
                <input
                  id={plan.id}
                  name='notification-method'
                  type='radio'
                  defaultChecked={plan.id === 'monthly'}
                  className='h-4 w-4 border-gray-300 focus:routera-700'
                  onChange={(e) => setPlanType(e.target.id)}
                />
                <label
                  htmlFor={plan.id}
                  className='ml-3 block text-sm font-medium leading-6 text-gray-900'
                >
                  {plan.title}{' '}
                  {plan.id === 'yearly' && (
                    <span className='ml-1 inline-block text-sm font-medium text-routera-700'>
                      SAVE 20%
                    </span>
                  )}
                </label>
              </div>
            </div>
          ))}
        </div>
        <div className='flex gap-x-7 flex-wrap'>
          {plans && Object.keys(plans).length
            ? plans[planType].map((plan: any) => (
                <BillingCard
                  className='mt-4'
                  key={plan.id}
                  plan={plan}
                  planType={planType}
                  currentPlanId={currentPlan?.planId}
                  currentPlanPrice={currentPlan?.price}
                  handleUpgradePlan={handleUpgradePlan}
                  loadingId={upgradeLoadingId}
                  disabled={!ability.can('update', 'setting.billing')}
                />
              ))
            : null}
        </div>
      </div>

      <ModalAtom
        showModal={showModal}
        setShowModal={setShowModal}
        showCancelBtn={true}
        handleSave={handleSeatsUpdate}
        resetHelper={resetSeatsState}
        closeOnOut={true}
        buttonText={'Add Seats'}
      >
        <div className='bg-white px-3 pt-4 pb-4 sm:p-6 sm:pb-4'>
          <div className='sm:flex sm:items-start'>
            <div className='mt-2 mx-2.5 sm:text-left w-[25rem] h-[6rem]'>
              <Dialog.Title
                as='h3'
                className='text-lg font-medium leading-6 text-gray-900'
              >
                Add Seats
              </Dialog.Title>
              <div className='mt-3 col-md-16'>
                <Input
                  type={'number'}
                  handleChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setSeats(e.target.valueAsNumber)
                  }
                  value={seats}
                  label={'Enter how many additional seats to add'}
                  customClass={''}
                  showLabel={true}
                  min={0}
                />
              </div>
            </div>
          </div>
        </div>
      </ModalAtom>
      <ModalAtom
        showModal={showConfirmation}
        setShowModal={setShowConfirmation}
        showCancelBtn={true}
        handleSave={onUpgradeConfirm}
        closeOnOut={true}
        buttonText={'Confirm'}
      >
        <div className='bg-white px-3 pt-4 pb-4 sm:p-6 sm:pb-4'>
          <div className='sm:flex sm:items-start'>
            <div className='mt-2 mx-2.5 sm:text-left w-[25rem] h-[4rem]'>
              <div className='text-gray-900'>
                You will be charged <strong>${upgradePlanData?.price}</strong>.
                Please click "Confirm" to upgrade.
              </div>
              <div className='mt-3 col-md-16'>{/* coupon input */}</div>
            </div>
          </div>
        </div>
      </ModalAtom>
    </div>
  )
}

export default Billing
