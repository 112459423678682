import Tabs from 'src/components/molecules/tabs/tabs'
import Billing from 'src/components/MyAccount/Billing'
import { DataTab } from 'src/types/tabs'
import { AbilityContext } from 'src/utils/permissionsCASL'
import { useAbility } from '@casl/react'
import NoPermission from 'src/components/templates/noPermission'

const BillingTab = () => {
  const ability = useAbility(AbilityContext)

  if (!ability.can('read', 'setting.billing')) {
    return <NoPermission value='read' />
  }

  const dataTabs: DataTab[] = [
    {
      label: 'Billing Info',
      id: 'billing-info',
      dataContentTab: <Billing />,
    },
  ]

  return (
    <section className='border-l border-gray-200'>
      <div className='p-4 px-5 bg-gray-100'>
        <h3 className='text-2xl block'>Settings</h3>
      </div>
      <Tabs dataTabs={dataTabs} />
    </section>
  )
}

export default BillingTab
