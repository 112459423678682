import { subscriptonService } from 'src/services/subscription.service'
import { createAsyncThunk } from '@reduxjs/toolkit'
import { ApiClient } from '../..'
import { authService } from '../../services/auth.service'
import { customService } from '../../services/customer.service'
import { AxiosResponse } from 'axios'

enum actiontype {
  CUSTOMER_REGISTER = 'CUSTOMER_REGISTER action type',
  GET_CUSTOMERS = 'GET_CUSTOMERS action type',
  setuserpassword123 = 'setuserpassword action type',
  changeoffice_status = 'changeoffice_status',
  changeoffice_status_for_team_member = 'changeoffice_status_for_team_memberchangeoffice_status_for_team_member',
  changeMaxLoad = 'CHANGE MAX LOAD',
  FETCH_SUBSCRIPTION_STATUS = 'Fetch Subscription Status',
  SET_TEAM_MEMBER_WORKING_HOURS = 'Set Team member working hours',
  GET_ACCOUNT = 'Get Account',
}
export const registerCustomer = createAsyncThunk(
  actiontype.CUSTOMER_REGISTER,
  async (payload: any) => {
    return await customService.createCustomer(payload)
  },
)

export const setTeamMemberWorkingHours = createAsyncThunk(
  actiontype.SET_TEAM_MEMBER_WORKING_HOURS,
  async (payload: any) => {
    return await authService.updateTeamMemberWorkingHours(payload)
  },
)

export const fetchSubscriptionStatus = createAsyncThunk(
  actiontype.FETCH_SUBSCRIPTION_STATUS,
  async () => {
    const result = await subscriptonService.fetchUserSubscription()
    const message = result.toString()

    if (message.includes('Error')) {
      localStorage.clear()
      window.location.href = '/'
    }

    return result
  },
)

export const getallcustomer = createAsyncThunk(
  actiontype.GET_CUSTOMERS,
  async () => {
    return await customService.getCustomer()
  },
)

export const setuserpassword = createAsyncThunk(
  actiontype.setuserpassword123,
  async (payload: any) => {
    return await authService.setuserpassword(payload)
  },
)

export const changeoffice_status_for_team_member = createAsyncThunk(
  actiontype.changeoffice_status_for_team_member,
  async (
    payload: {
      office_status: boolean
      user_id: string
    },
    thunkApi,
  ) => {
    const result = await authService.changeoffice_status(payload)
    if (result) return result
    else thunkApi.rejectWithValue('')
  },
)

export const changeMaxLoad = createAsyncThunk(
  actiontype.changeMaxLoad,
  async (
    payload: Array<{
      userId: string
      max_load_capacity: number
    }>,
    thunkApi,
  ) => {
    const result = await authService.changeMaxLoad(payload)
    if (result) return result
    else thunkApi.rejectWithValue('')
  },
)

export const get_working_hours = createAsyncThunk(
  'get_working_hours/get_working_hours',
  async (payload, thunkApi) => {
    const result = await ApiClient.get('/users/get-working-hours')
    if (result) return result
    else thunkApi.rejectWithValue('')
  },
)

export const getAccount = createAsyncThunk(
  actiontype.GET_ACCOUNT,
  async (accountId: string) => {
    const result = await customService.getAccount(accountId)
    return result
  },
)

export const hideAllocationLimitsBanner = createAsyncThunk(
  'hidden banner',
  async (
    payload: {
      percentage: string
    },
    thunkApi,
  ) => {
    const result = await customService.hideAllocationLimitsBanner(payload)
    if (result) return result
    else thunkApi.rejectWithValue('')
  },
)
